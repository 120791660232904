import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as groupsAnEnMGBKy4Meta } from "/vercel/path0/pages/groups.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as menue28QHjVRIcMeta } from "/vercel/path0/pages/menu.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as _91item_93zKgFSelgFtMeta } from "/vercel/path0/pages/vacancies/[item].vue?macro=true";
import { default as indexp3qWQtgtPJMeta } from "/vercel/path0/pages/vacancies/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___nl",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___en",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/en/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___nl",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___en",
    path: contactJnsDpYVejzMeta?.path ?? "/en/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: groupsAnEnMGBKy4Meta?.name ?? "groups___nl",
    path: groupsAnEnMGBKy4Meta?.path ?? "/groepen",
    meta: groupsAnEnMGBKy4Meta || {},
    alias: groupsAnEnMGBKy4Meta?.alias || [],
    redirect: groupsAnEnMGBKy4Meta?.redirect,
    component: () => import("/vercel/path0/pages/groups.vue").then(m => m.default || m)
  },
  {
    name: groupsAnEnMGBKy4Meta?.name ?? "groups___en",
    path: groupsAnEnMGBKy4Meta?.path ?? "/en/groups",
    meta: groupsAnEnMGBKy4Meta || {},
    alias: groupsAnEnMGBKy4Meta?.alias || [],
    redirect: groupsAnEnMGBKy4Meta?.redirect,
    component: () => import("/vercel/path0/pages/groups.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___nl",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/en",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: menue28QHjVRIcMeta?.name ?? "menu___nl",
    path: menue28QHjVRIcMeta?.path ?? "/menus",
    meta: menue28QHjVRIcMeta || {},
    alias: menue28QHjVRIcMeta?.alias || [],
    redirect: menue28QHjVRIcMeta?.redirect,
    component: () => import("/vercel/path0/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: menue28QHjVRIcMeta?.name ?? "menu___en",
    path: menue28QHjVRIcMeta?.path ?? "/en/menus",
    meta: menue28QHjVRIcMeta || {},
    alias: menue28QHjVRIcMeta?.alias || [],
    redirect: menue28QHjVRIcMeta?.redirect,
    component: () => import("/vercel/path0/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___nl",
    path: sitemapmtRscZaA8vMeta?.path ?? "/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___en",
    path: sitemapmtRscZaA8vMeta?.path ?? "/en/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: _91item_93zKgFSelgFtMeta?.name ?? "vacancies-item___nl",
    path: _91item_93zKgFSelgFtMeta?.path ?? "/vacatures/:item()",
    meta: _91item_93zKgFSelgFtMeta || {},
    alias: _91item_93zKgFSelgFtMeta?.alias || [],
    redirect: _91item_93zKgFSelgFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/vacancies/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93zKgFSelgFtMeta?.name ?? "vacancies-item___en",
    path: _91item_93zKgFSelgFtMeta?.path ?? "/en/vacancies/:item()",
    meta: _91item_93zKgFSelgFtMeta || {},
    alias: _91item_93zKgFSelgFtMeta?.alias || [],
    redirect: _91item_93zKgFSelgFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/vacancies/[item].vue").then(m => m.default || m)
  },
  {
    name: indexp3qWQtgtPJMeta?.name ?? "vacancies___nl",
    path: indexp3qWQtgtPJMeta?.path ?? "/vacatures",
    meta: indexp3qWQtgtPJMeta || {},
    alias: indexp3qWQtgtPJMeta?.alias || [],
    redirect: indexp3qWQtgtPJMeta?.redirect,
    component: () => import("/vercel/path0/pages/vacancies/index.vue").then(m => m.default || m)
  },
  {
    name: indexp3qWQtgtPJMeta?.name ?? "vacancies___en",
    path: indexp3qWQtgtPJMeta?.path ?? "/en/vacancies",
    meta: indexp3qWQtgtPJMeta || {},
    alias: indexp3qWQtgtPJMeta?.alias || [],
    redirect: indexp3qWQtgtPJMeta?.redirect,
    component: () => import("/vercel/path0/pages/vacancies/index.vue").then(m => m.default || m)
  }
]