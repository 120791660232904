/**
 * Initialize reCAPTCHA if the public sitekey is set.
 */
export default defineNuxtPlugin(() => {
	const config = useRuntimeConfig();

	if (!config.public.recaptcha.siteKey) {
		return {
			provide: {
				executeRecaptcha: () => Promise.reject('No reCAPTCHA site key provided'),
				installRecaptcha: () => {},
			},
		};
	}

	return {
		provide: {
			executeRecaptcha,
			installRecaptcha,
		},
	};
});

const installRecaptcha = () => {
	const config = useRuntimeConfig();

	// Only add the script if it's not already there
	if (document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]')) {
		return;
	}

	const script = document.createElement('script');
	const source = `https://www.google.com/recaptcha/api.js?render=${config.public.recaptcha.siteKey}`;
	script.src = source;
	document.head.appendChild(script);
};

const executeRecaptcha = async () => {
	const config = useRuntimeConfig();

	return new Promise((resolve) => {
		window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(config.public.recaptcha.siteKey, {
					action: 'submit',
				})
				.then((token) => {
					resolve(token);
				});
		});
	});
};
